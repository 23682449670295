
:root {
    --main: #222831;
    --main-darken: #1a1f25;
    --secondary : #4f8a8b;
    --light : #ffffff;
    --third : #fbd46d;
    --pading : 10px
}

.jamms-jumbotron{
    /* background:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./../Images/jumbotron.jpg'); */
    background-image: linear-gradient( 65.5deg,  #222831 -15.1%, #4f8a8b 71.5% );
    /* background-image: linear-gradient( 109.6deg, #222831 11.2%, #4f8a8b 91.1% ); */
    height: 700px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.jamms-package-sablon-container{
    background-image: linear-gradient( 109.6deg,  rgba(204,228,247,1) 11.2%, rgba(237,246,250,1) 100.2% );
}


.btn-secondary:focus{
    background-color: var(--secondary);
}

.jamms-banner-subscription{
    background-image: linear-gradient(180deg,#4f8a8b,#222831);
    border-radius: 25px;
}
