
.jamms-clickable-el{
    cursor: pointer;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.jamms-clickable-el:hover{
    opacity: 0.7;
}

.jamms-clickable-el:focus{
    opacity: 0.9;
}

.jamms-active-state{
    font-weight: bolder;
    color: brown;
}


.jamms-fixed{
    position: fixed;
    left: 0px;
    right: 0px;
    z-index: 1;
}